<template>
  <div class="side-menu">
    <div class="logo">
      <span>智能设备管理</span>
    </div>

    <el-scrollbar>
      <el-menu
        :router="true"
        :default-active="activeRouter"
        background-color="transparent"
        text-color="#fff"
        active-text-color="#fff"
      >
        <SideMenuItem v-for="route in routes" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar>
  </div>
</template>

<script>
import { mapState } from 'vuex'
// import SideMenuItem from './SideMenuItem.vue'
import SideMenuItem from './SideMenuItem_2.vue'

export default {
  name: '',
  components: { SideMenuItem },

  props: {},

  data() {
    return {
      onlyOneChild: null
    }
  },

  computed: {
    ...mapState('permission', ['routes']),
    // routes() {
    //   return this.$router.options.routes
    // },

    activeRouter() {
      return this.$route.path
    }
  },

  watch: {},

  created() {},

  methods: {}
}
</script>

<style scoped lang="scss">
.side-menu {
  height: 100%;

  .logo {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 60px;
    font-size: 24px;
    color: #fff;
  }

  /deep/ .el-scrollbar {
    height: calc(100% - 60px - 20px);
    padding-bottom: 20px;

    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }

  /deep/ .el-menu {
    margin-right: -1px;

    .first-level-menu {
    }

    .el-submenu {
      .el-submenu__title {
        margin: 6px;
        border-radius: 5px;

        .el-submenu__icon-arrow {
          font-size: 18px;
          color: #fff;
        }
      }

      .el-submenu__title:hover {
        background-color: #5470c6 !important;
      }

      .el-menu-item {
        margin: 6px;
        margin-left: 16px;
        border-radius: 5px;
      }
    }

    .el-menu-item {
      margin: 6px;
      border-radius: 5px;
    }

    .el-menu-item:hover {
      background-color: #5470c6 !important;
    }

    .el-menu-item.is-active {
      background-color: #5470c6 !important;
    }
  }
}
</style>
