<template>
  <div class="navbar">
    <div class="left-menu"></div>

    <div class="right-menu">
      <screen-full></screen-full>

      <el-dropdown class="avatar-container" trigger="click">
        <div class="avatar-wrapper">
          <el-avatar v-if="userInfo.avatar" :src="userInfo.avatar"></el-avatar>
          <el-avatar v-else icon="el-icon-user-solid"></el-avatar>
          <span class="name">{{ userInfo.nickname || 'user' }}</span>
          <i class="el-icon el-icon-arrow-down" />
        </div>
        <el-dropdown-menu slot="dropdown" class="user-dropdown">
          <router-link to="/">
            <el-dropdown-item>首页</el-dropdown-item>
          </router-link>
          <router-link to="/account/personalCenter">
            <el-dropdown-item>个人中心</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="handleLogout">
            <span>退出登录</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import ScreenFull from '@/components/ScreenFull.vue'

export default {
  name: '',
  components: { ScreenFull },

  props: {},

  data() {
    return {}
  },

  computed: { ...mapState('user', ['userInfo']) },

  watch: {},

  created() {},

  methods: {
    ...mapMutations('user', ['logout']),

    // 退出登录
    async handleLogout() {
      const res = await this.$api.login.logout()

      if (res.code === '00000') {
        this.$message.success('注销登录成功！')

        localStorage.clear()
        this.logout()

        this.$router.push({
          path: '/login'
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;

  .right-menu {
    display: flex;
    align-items: center;

    .avatar-container {
      margin-left: 20px;
      .avatar-wrapper {
        display: flex;
        align-items: center;

        .name {
          margin: 0 5px;
        }

        .el-icon {
          margin-top: 5px;
        }
      }
    }
  }
}
</style>
