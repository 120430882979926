<template>
  <div v-if="!item.hidden" class="first-level-menu">
    <el-submenu :index="item.path">
      <template slot="title">
        <!-- <i :class="'iconfont' + ' ' + item.meta.icon"></i> -->
        <span>{{ item.meta.title }}</span>
      </template>
      <div v-for="child in item.children" :key="child.path">
        <el-menu-item :index="item.path + '/' + child.path" v-if="!child.hidden">
          <span>{{ child.meta.title }}</span>
        </el-menu-item>
      </div>
    </el-submenu>
  </div>
</template>

<script>
export default {
  name: 'SideMenuItem',
  components: {},

  props: {
    item: {
      type: Object,
      required: true
    }
  },

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {}
}
</script>

<style scoped lang="scss">
.first-level-menu {
}
</style>
