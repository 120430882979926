<template>
  <el-container class="wrapper">
    <el-aside width="240px">
      <SideMenu></SideMenu>
    </el-aside>

    <el-container>
      <el-header>
        <Navbar></Navbar>
      </el-header>

      <el-main>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import SideMenu from './components/SideMenu.vue'
// import SideMenu from './Sidebar/index.vue'
import Navbar from './components/Navbar.vue'

export default {
  name: '',
  components: { SideMenu, Navbar },

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {}
}
</script>

<style scoped lang="scss">
.wrapper {
  height: 100vh;

  .el-aside {
    background: #282c34;
    // overflow-y: hidden;
  }

  .el-header {
    border-bottom: 1px solid #ebeef5;
  }

  .el-main {
    background-color: #f7f8f9;
  }
}
</style>
