// 全屏组件

<template>
  <i class="el-icon-full-screen" @click="toggle"></i>
</template>

<script>
import ScreenFull from 'screenfull'

export default {
  name: '',
  components: {},

  props: {},

  data() {
    return {}
  },

  computed: {},

  watch: {},

  created() {},

  methods: {
    toggle() {
      ScreenFull.toggle()
    }
  }
}
</script>

<style scoped lang="scss">
.el-icon-full-screen {
  cursor: pointer;
}
</style>
